import React from 'react'
import { useMediaQuery } from '@react-hook/media-query'
import styled from '@emotion/styled'
import { HeadingContainer } from '../components/common/typography'
import AppLayout from '../components/layouts/appLayout'
import { MOBILE_DEVICE } from '../components/common/breakpoints'
import SEO from '../components/seo'
import { TwitterTimelineEmbed, TwitterFollowButton } from 'react-twitter-embed'

const Container = styled.div`
  display: grid;
  grid-auto-flow: row;
  row-gap: 1rem;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 8rem;
  box-sizing: border-box;
  justify-content: center;
`

const Iframe = styled.iframe`
  display: block;
  overflow: hidden;
  border: none;
  box-sizing: border-box;
`
const TwitterDiv = styled.div`
  display: block;
  overflow: hidden;
  border: none;
  box-sizing: border-box;
  padding-bottom: 2rem;
`

const FollowButton = styled.div`
  display: flex;
  justify-content: center;
`

const EventsPage = () => {
  const matchMobile = useMediaQuery(`(min-width: ${MOBILE_DEVICE}px)`)
  return (
    <AppLayout  >
      <SEO title="Events" canonical="events" />
      <HeadingContainer title="Events"></HeadingContainer>
      <Container>
        <FollowButton>
          <TwitterFollowButton
            screenName="alio_it"
            options={{ size: 'large' }}
          />
        </FollowButton>
        <TwitterDiv>
          <TwitterTimelineEmbed
            sourceType="profile"
            screenName="alio_it"
            options={{ height: 800, id: `profile:https://twitter.com/alio_it` }}
            noScrollbar
          />
        </TwitterDiv>
        <div>
          <Iframe
            src={`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Falioits%2Fposts%2F3742105992547145&width=${!matchMobile ? '320' : '500'
              }&show_text=true&height=${!matchMobile ? '316' : '498'}&appId`}
            width={!matchMobile ? '360' : '500'}
            height={!matchMobile ? '420' : '498'}
            scrolling="no"
            frameBorder="0"
            allowFullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></Iframe>
        </div>
        <div>
          <Iframe
            src={`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Falioits%2Fposts%2F3815259338565143&width=${!matchMobile ? '320' : '500'
              }&show_text=true&height=${!matchMobile ? '316' : '498'}&appId`}
            width={!matchMobile ? '360' : '500'}
            height={!matchMobile ? '420' : '498'}
            scrolling="no"
            frameBorder="0"
            allowFullscreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          ></Iframe>
        </div>
      </Container>
    </AppLayout>
  )
}

export default EventsPage
